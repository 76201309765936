import { Knob as AuxKnob, HSLToHex } from '@deutschesoft/aux-widgets/src/index.pure.js';
import { defineRender } from '@deutschesoft/aux-widgets/src/renderer.js';

export class MyKnob extends AuxKnob {
  static get renderers(){
    return [
      defineRender("value", 
        function (value) {
          const color = CSSColorSetter(0.56, 0.90, 0, 100, value);
          (this).element.style.setProperty('--aux-knob-accent',color); 
        }
      )
    ]
  }
}

function CSSColorSetter (cmin, cmax, vmin, vmax, value) {
  const hue = ((value - vmin) / (vmax - vmin)) * (cmax - cmin) + cmin;
  const color = HSLToHex(hue, 1, 0.6);
  return "#"+color;
}