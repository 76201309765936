import { MyKnob } from './Knob.js';
import { componentFromWidget } from '@deutschesoft/use-aux-widgets';

const GainKnob = componentFromWidget(
  MyKnob,
  {
    setting$: {
      name: 'value'
    },
  },
  {
    preset: "huge",
    step: 1,
    show_dots: true,
    min: 0,
    max: 100,
    /*
    dots_defaults: {length: 4, margin: 2, width: 4},
    dots: [0,1,2,3,4,5,10,20,30,40,50,60,70,80,90,100],
    markers: [
      {from: 0, to: 20}
  ],
    labels: [
        {pos: 0, label: "\u221E", class: "infinity"}, 
        {pos: 1, label: "1"}, 
        {pos: 2, label: "2"}, 
        {pos: 3, label: "3"}, 
        {pos: 4, label: "4"}, 
        {pos: 5, label: "5"}, 
        {pos: 6, label: "6"}, 
        {pos: 7, label: "7"}, 
        {pos: 8, label: "8"}, 
        {pos: 9, label: "9"}, 
        {pos: 10, label: "10"}, 
        {pos: 11, label: "11", color: "#ff0000"}, 
    ],
    labels_defaults: {align: "outer", margin: 10},
    labels: [
      {pos: 0, label: "\u221E", class: "infinity"}, 
      {pos: 100, label: "100"}, 
    ]
    */
  },
  'GainKnob'
);

export default GainKnob;
