import { MyFader } from './Fader.js';
import { componentFromWidget } from '@deutschesoft/use-aux-widgets';

const ChannelFader = componentFromWidget(
  MyFader,
  {
    gain$: {
      name: 'value',
    },
    label$: {
      name: 'label'
    },
    scale$: {
      name: 'show_scale'
    },
  },
  {
    snap: 1,
  },
  'ChannelFader'
);

export default ChannelFader;
