import { getBackendValue, DynamicValue, combineLatest } from '@deutschesoft/awml/src/index.pure';

// `type definitions` of the entries required for typescript ebb and flow

interface IEqModel {
  value$: DynamicValue<number>;
  label$: DynamicValue<string>;
  scale$: DynamicValue<boolean>;
}

interface IVolumeModel {
  setting$: DynamicValue<number>;
}

interface IMeterModel {
  values$: DynamicValue<number[]>;
}

interface IDeviceModel {
  eq: IEqModel[];
  volume: IVolumeModel[];
  meter: IMeterModel;
}

function buildEqModel (path:string, scale?:boolean):IEqModel {
  return {
    value$:  getBackendValue(`ESP32:Equalizer/`+path+`/Gain`) as DynamicValue<number>,
    label$: DynamicValue.fromConstant(path),
    scale$:  scale?DynamicValue.fromConstant(scale):DynamicValue.fromConstant(false),
  }
}

function buildVolumeModel (path:string):IVolumeModel {
  return {
    setting$: getBackendValue(path+`/Setting`) as DynamicValue<number>,
  }
}

function buildMeterModel (path_left:string, path_right:string):IMeterModel {
  const left$ = getBackendValue(path_left+`/Reading`) as DynamicValue<number>;
  const right$ = getBackendValue(path_right+`/Reading`) as DynamicValue<number>;
  return {
    values$: combineLatest([left$,right$])
  }
}

const DeviceModel:IDeviceModel = {
    eq: [
      buildEqModel('31Hz'),
      buildEqModel('62Hz'),
      buildEqModel('125Hz'),
      buildEqModel('250Hz'),
      buildEqModel('500Hz'),
      buildEqModel('1kHz'),
      buildEqModel('2kHz'),
      buildEqModel('4kHz'),
      buildEqModel('8kHz'),
      buildEqModel('16kHz',true),
    ],
    meter:
      buildMeterModel('ESP32:Meters/Output L','ESP32:Meters/Output R'),
    volume: [
      buildVolumeModel('ESP32:Volume'),
    ]
  };

export default DeviceModel;

