import { MultiMeter } from '@deutschesoft/aux-widgets/src/index.pure.js';
import { componentFromWidget } from '@deutschesoft/use-aux-widgets';

const ChannelMeter = componentFromWidget(
  MultiMeter,
  {
    leveldata$: {
      name: 'value', //could be any parameter value is transformed
     // 'transformReceive': function(v){return (v+5)*7}
    },
    labeldata$: {
      name: 'label', //there is no label data in this oca device 
    }
  },
  {
    scale: 'decibel',
    log_factor: 3,
    labels: ['L','R'],
    min: -96,
    max: 0,
    segment: 3,
    foreground: 'rgba(255,255,255,1)',
    hold_size: .5,
    //show_hold: true,
    peak_value: -1,
    show_label: true,
    auto_hold: 500, 
    clipping: -1,
    clip: true,
    show_clip: true,
    auto_clip: 500,
    falling: 15,
    falling_duration: 1000,
    gradient: {'-96':'#00a3d6', '0':'#ff01ab'} 
  },
  'ChannelMeter'
);

export default ChannelMeter;
