import { DynamicValue } from '@deutschesoft/awml/src/index.pure';
import { useDynamicValueReadonly } from '@deutschesoft/use-aux-widgets';

export function Logo (props:{
  model$: DynamicValue<number[]>;
}) {
  const {model$} = props;
  const values = useDynamicValueReadonly(model$,[-96,-96]);
  const cls = values[0]>=0||values[1]>=0?'clipping':'';
  return (
    <svg className={ cls } id='logo' xmlns="http://www.w3.org/2000/svg" width="192" height="28" viewBox="0 0 192 28" version="1.1">
    <defs id="defs4" />
    <path
     d="M 36,0 36,8 8,8 8,28 40,28 40,0 Z M 0,8 0,16 4,16 4,8 Z m 44,0 0,20 32,0 0,-4 -28,0 0,-4 28,0 0,-12 z m 36,0 0,20 32,0 0,-20 -4,0 0,16 -24,0 0,-16 z m 36,0 0,12 28,0 0,4 -28,0 0,4 28,0 4,0 0,-12 -28,0 0,-4 28,0 0,-4 z m 36,0 0,20 32,0 0,-20 z m 36,0 0,8 4,0 0,-8 z m -176,4 24,0 0,12 -24,0 z m 36,0 24,0 0,4 -24,0 z m 108,0 24,0 0,12 -24,0 z m -156,8 0,8 4,0 0,-8 z m 188,0 0,8 4,0 0,-8 z"
     id="deuso" />
    </svg>
  )
}
