import React, { ChangeEvent, useMemo, useState, useCallback } from 'react';
import { AES70Backend } from '@deutschesoft/awml/src/backends/aes70';
import { useBackend } from '@deutschesoft/use-aux-widgets';
import Fader from '../widgets/ChannelFader';
import Meter from '../widgets/MultiMeter';
import Knob from '../widgets/GainKnob';
import { Logo } from '../widgets/Logo';

import DeviceModel from '../model/DeviceModel';

function createBackendFactory(IP:string, Port:string):any {
  return () => {
      return new AES70Backend({
      url: `ws://${IP}:${Port}`,
    });
  }
}

function UI() {
  const [ IP, setIP ] = useState<string>('');
  const [ Port, setPort ] = useState<string>('');
  const [ connecting, setConnecting ] = useState(false);

  const onIPChanged = useCallback((e:ChangeEvent<HTMLInputElement>)=> {
    setIP(e.target.value)
  }, [setIP]);

  const onPortChanged = useCallback((e:ChangeEvent<HTMLInputElement>)=> {
    setPort(e.target.value)
  }, [setPort]);

 const onConnectClicked = useCallback(()=> {
    if (connecting) {
      setConnecting(false)
    } else if (IP && Port) {
      setConnecting(true)
    }
  }, [ IP, Port, connecting ]);

 const createBackend = useMemo(() => connecting ? createBackendFactory(IP, Port) : null, [ connecting, IP, Port ]);

  const [ backend ] = useBackend('ESP32', createBackend);

  const connected = !!backend;

  return (
    <div id="UI" className="App">
    {
      connected?
      <>
        { DeviceModel.eq.map((model) => <Fader min={ -13 } max={ 13 } label$={ model.label$ } scale$={ model.scale$ } gain$={ model.value$ }/>) }
        {  <Meter leveldata$={ DeviceModel.meter.values$ }/> }
        <div className='right'>
          { <Logo model$={ DeviceModel.meter.values$ }/> }
          { DeviceModel.volume.map((model) => <Knob setting$={ model.setting$ } />) }
          <span>Volume</span>
        </div>
      </>:<>
        <div className='formContainer'>
          <span><b>Technology Preview:</b> AES70 + AUX Control Widgets</span>
	  <p><b>IP:</b> 137.184.46.123 <b>Port:</b> 1058</p>
          <div className='form'>
          <input type='text' onChange={ onIPChanged } placeholder='Enter IP' disabled={ connecting }></input>
          <input type='text' onChange={ onPortChanged } placeholder='Enter Port' disabled={ connecting }></input>
          <button onClick={ onConnectClicked }>{ !connecting ? 'Connect' : 'Stop' }</button>
          </div>
          <div className='aux-logo'></div>

        </div>
        {
          connecting ? <div>Connecting to { IP }:{ Port } </div> : null
        }
      </>
    }
    </div>
  );
}

export default UI;
